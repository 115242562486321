import { BlockType as BlockTypEnum } from '$lib/graphql/enums'
import type { BlockType, CustomIcon } from '$lib/graphql/types.d'

export type CustomIconDefinitions = { name: CustomIcon; svgUrl: string; category: string }

export const customIconCategories = [
	{ name: 'sports', label: 'Sports' },
	{ name: 'food', label: 'Food' },
	{ name: 'transportation', label: 'Transportation' },
	{ name: 'amusement', label: 'Amusement' },
	{ name: 'animals', label: 'Animals' },
	{ name: 'architecture', label: 'Architecture' },
	{ name: 'nature', label: 'Nature' },
	{ name: 'shopping', label: 'Shopping' },
	{ name: 'accommodation', label: 'Accommodation' },
	{ name: 'service', label: 'Service' },
]

export const customIcons: CustomIconDefinitions[] = [
	{ name: 'AMERICAN_FOOTBALL_BALL', svgUrl: 'american-football-ball-1.svg', category: 'sports' },
	{ name: 'AMERICAN_FOOTBALL_HELMET', svgUrl: 'american-football-helmet.svg', category: 'sports' },
	{ name: 'ARCHERY_BOW', svgUrl: 'archery-bow.svg', category: 'sports' },
	{ name: 'BASEBALL_BAT_BALL', svgUrl: 'baseball-bat-ball.svg', category: 'sports' },
	{ name: 'BASEBALL_GLOVE', svgUrl: 'baseball-glove.svg', category: 'sports' },
	{ name: 'BASKETBALL_BALL', svgUrl: 'basketball-ball.svg', category: 'sports' },
	{ name: 'BIKING_HELMET_PERSON', svgUrl: 'biking-helmet-person.svg', category: 'sports' },
	{ name: 'BIKING_PERSON', svgUrl: 'biking-person.svg', category: 'sports' },
	{ name: 'BOWLING_PINS', svgUrl: 'bowling-pins.svg', category: 'sports' },
	{ name: 'BOWLING_SET', svgUrl: 'bowling-set.svg', category: 'sports' },
	{ name: 'CLIMBING_MOUNTAIN', svgUrl: 'climbing-mountain.svg', category: 'sports' },
	{ name: 'DANCING_BALLET', svgUrl: 'dancing-ballet.svg', category: 'sports' },
	{ name: 'GOLF_BALL', svgUrl: 'golf-ball.svg', category: 'sports' },
	{ name: 'GOLF_EQUIPMENT', svgUrl: 'golf-equipment.svg', category: 'sports' },
	{ name: 'GOLF_HOLE_AIM', svgUrl: 'golf-hole-aim.svg', category: 'sports' },
	{ name: 'GOLF_HOLE', svgUrl: 'golf-hole.svg', category: 'sports' },
	{ name: 'HOCKEY_PUCK_STICK', svgUrl: 'hockey-puck-stick.svg', category: 'sports' },
	{ name: 'MARTIAL_ARTS_SUMO', svgUrl: 'martial-arts-sumo.svg', category: 'sports' },
	{ name: 'MARTIAL_ARTS_SWORDS', svgUrl: 'martial-arts-swords.svg', category: 'sports' },
	{ name: 'PARALYMPICS_SWIMMING', svgUrl: 'paralympics-swimming.svg', category: 'sports' },
	{
		name: 'PARALYMPICS_WEIGHTLIFTING',
		svgUrl: 'paralympics-weightlifting.svg',
		category: 'sports',
	},
	{ name: 'PING_PONG_PADDLE', svgUrl: 'ping-pong-paddle.svg', category: 'sports' },
	{ name: 'POOL_BLACK_BALL', svgUrl: 'pool-black-ball.svg', category: 'sports' },
	{ name: 'ROLLERBLADES', svgUrl: 'rollerblades.svg', category: 'sports' },
	{ name: 'SHOOTING_RIFLE_TARGET', svgUrl: 'shooting-rifle-target.svg', category: 'sports' },
	{ name: 'SKATING_SHOES', svgUrl: 'skating-shoes.svg', category: 'sports' },
	{ name: 'SKIING_BOARD_SLIDE', svgUrl: 'skiing-board-slide.svg', category: 'sports' },
	{ name: 'SKIING_CABLE_CAR', svgUrl: 'skiing-cable-car.svg', category: 'sports' },
	{ name: 'SKIING_SNOWBOARD', svgUrl: 'skiing-snowboard.svg', category: 'sports' },
	{ name: 'SOCCER_BALL', svgUrl: 'soccer-ball.svg', category: 'sports' },
	{ name: 'SPORT_HOCKEY', svgUrl: 'sport-hockey.svg', category: 'sports' },
	{ name: 'SPORT_HORSE_RIDING', svgUrl: 'sport-horse-riding.svg', category: 'sports' },
	{ name: 'SPORT_PARAGLIDING', svgUrl: 'sport-paragliding.svg', category: 'sports' },
	{ name: 'SWIMMING_JUMP', svgUrl: 'swimming-jump.svg', category: 'sports' },
	{ name: 'TENNIS_RACQUET', svgUrl: 'tennis-racquet.svg', category: 'sports' },
	{ name: 'VOLLEYBALL_BALL', svgUrl: 'volleyball-ball.svg', category: 'sports' },
	{ name: 'ANIMAL_PRODUCTS_CHEESE', svgUrl: 'animal-products-cheese.svg', category: 'food' },
	{
		name: 'ANIMAL_PRODUCTS_DRUMSTICKS',
		svgUrl: 'animal-products-drumsticks.svg',
		category: 'food',
	},
	{ name: 'ANIMAL_PRODUCTS_EGGS', svgUrl: 'animal-products-eggs.svg', category: 'food' },
	{ name: 'ANIMAL_PRODUCTS_FRIED_EGG', svgUrl: 'animal-products-fried-egg.svg', category: 'food' },
	{ name: 'ANIMAL_PRODUCTS_TURKEY', svgUrl: 'animal-products-turkey.svg', category: 'food' },
	{ name: 'ASIAN_FOOD_NOODLES', svgUrl: 'asian-food-noodles.svg', category: 'food' },
	{ name: 'ASIAN_FOOD_PORK_BUN', svgUrl: 'asian-food-pork-bun.svg', category: 'food' },
	{ name: 'ASIAN_FOOD_RICE', svgUrl: 'asian-food-rice.svg', category: 'food' },
	{ name: 'BARBECUE_GRILL', svgUrl: 'barbecue-grill.svg', category: 'food' },
	{ name: 'BEER_MUG', svgUrl: 'beer-mug.svg', category: 'food' },
	{ name: 'BREAD_BAGUETTE', svgUrl: 'bread-baguette.svg', category: 'food' },
	{ name: 'BREAD_SLICE', svgUrl: 'bread-slice.svg', category: 'food' },
	{
		name: 'BREAKFAST_CEREAL_BOWL_SPOON',
		svgUrl: 'breakfast-cereal-bowl-spoon.svg',
		category: 'food',
	},
	{ name: 'BREAKFAST_CROISSANT', svgUrl: 'breakfast-croissant-1.svg', category: 'food' },
	{ name: 'BREAKFAST_ENGLISH', svgUrl: 'breakfast-english.svg', category: 'food' },
	{ name: 'CAKE_CHERRY', svgUrl: 'cake-cherry.svg', category: 'food' },
	{ name: 'COOKIE', svgUrl: 'cookie.svg', category: 'food' },
	{ name: 'CUPCAKE', svgUrl: 'cupcake.svg', category: 'food' },
	{ name: 'FAST_FOOD_BURGER', svgUrl: 'fast-food-burger-drink.svg', category: 'food' },
	{ name: 'FAST_FOOD_DOUBLE_BURGER', svgUrl: 'fast-food-double-burger.svg', category: 'food' },
	{ name: 'FAST_FOOD_FRENCH_FRIES', svgUrl: 'fast-food-french-fries.svg', category: 'food' },
	{ name: 'FAST_FOOD_HOT_DOG', svgUrl: 'fast-food-hot-dog.svg', category: 'food' },
	{ name: 'FAST_FOOD_PIZZA', svgUrl: 'fast-food-pizza.svg', category: 'food' },
	{ name: 'FAST_FOOD_PIZZA_SLICE', svgUrl: 'fast-food-pizza.svg', category: 'food' },
	{ name: 'FAST_FOOD_PRETZEL', svgUrl: 'fast-food-pretzel.svg', category: 'food' },
	{ name: 'FAST_FOOD_TACOS', svgUrl: 'fast-food-tacos.svg', category: 'food' },
	{ name: 'FAST_FOOD_WRAP', svgUrl: 'fast-food-wrap.svg', category: 'food' },
	{ name: 'FRUIT_APPLE', svgUrl: 'fruit-apple.svg', category: 'food' },
	{ name: 'FRUIT_BANANA', svgUrl: 'fruit-banana-1.svg', category: 'food' },
	{ name: 'FRUIT_CHERRY', svgUrl: 'fruit-cherry.svg', category: 'food' },
	{ name: 'FRUIT_GRAPES', svgUrl: 'fruit-grapes.svg', category: 'food' },
	{ name: 'FRUIT_LEMON', svgUrl: 'fruit-lemon.svg', category: 'food' },
	{ name: 'FRUIT_STRAWBERRY', svgUrl: 'fruit-strawberry-1.svg', category: 'food' },
	{ name: 'FRUIT_WATERMELON', svgUrl: 'fruit-watermelon.svg', category: 'food' },
	{ name: 'ICE_CREAM_BITE', svgUrl: 'ice-cream-bite.svg', category: 'food' },
	{ name: 'ICE_CREAM_BOWL', svgUrl: 'ice-cream-bowl.svg', category: 'food' },
	{ name: 'ICE_CREAM_CONE', svgUrl: 'ice-cream-cone.svg', category: 'food' },
	{ name: 'PASTA_BOWL_WARM', svgUrl: 'pasta-bowl-warm.svg', category: 'food' },
	{ name: 'PASTA_NOODLES', svgUrl: 'pasta-noodles.svg', category: 'food' },
	{ name: 'PIE', svgUrl: 'pie.svg', category: 'food' },
	{ name: 'RESTAURANT_EATING_SET', svgUrl: 'restaurant-eating-set.svg', category: 'food' },
	{ name: 'RESTAURANT_FORK_SPOON', svgUrl: 'restaurant-fork-spoon.svg', category: 'food' },
	{ name: 'SEAFOOD_CRAB', svgUrl: 'seafood-crab.svg', category: 'food' },
	{ name: 'SEAFOOD_FISH', svgUrl: 'seafood-fish.svg', category: 'food' },
	{ name: 'SEAFOOD_SHRIMP', svgUrl: 'seafood-shrimp.svg', category: 'food' },
	{ name: 'VEGETABLES_CARROT', svgUrl: 'vegetables-carrot.svg', category: 'food' },
	{ name: 'VEGETABLES_CORN', svgUrl: 'vegetables-corn.svg', category: 'food' },
	{ name: 'VEGETABLES_SALAD', svgUrl: 'vegetables-salad.svg', category: 'food' },

	{ name: 'AIRCRAFT_CHOPPER', svgUrl: 'aircraft-chopper-1.svg', category: 'transportation' },
	{
		name: 'AIRCRAFT_HOT_AIR_BALLOON',
		svgUrl: 'aircraft-hot-air-balloon-2.svg',
		category: 'transportation',
	},
	{ name: 'AIRPORT_ROAD', svgUrl: 'airport-road.svg', category: 'transportation' },
	{ name: 'AMUSEMENT_PARK_CASTLE', svgUrl: 'amusement-park-castle.svg', category: 'amusement' },
	{
		name: 'AMUSEMENT_PARK_FERRIS_WHEEL',
		svgUrl: 'amusement-park-ferris-wheel.svg',
		category: 'amusement',
	},
	{
		name: 'AMUSEMENT_PARK_ROLLERCOASTER',
		svgUrl: 'amusement-park-rollercoaster.svg',
		category: 'amusement',
	},
	{ name: 'ANCHOR', svgUrl: 'anchor.svg', category: 'transportation' },
	{ name: 'BAGGAGE_CART', svgUrl: 'baggage-cart.svg', category: 'transportation' },
	{ name: 'BEACH_PALM_SUNBED', svgUrl: 'beach-palm-sunbed.svg', category: 'amusement' },
	{ name: 'BEACH_PARASOL_WATER', svgUrl: 'beach-parasol-water-1.svg', category: 'amusement' },
	{ name: 'BICYCLE', svgUrl: 'bicycle.svg', category: 'transportation' },
	{ name: 'BOARD_GAME_DICE', svgUrl: 'board-game-dice-1.svg', category: 'amusement' },
	{ name: 'BRIDGE', svgUrl: 'bridge-1.svg', category: 'transportation' },
	{ name: 'BUS', svgUrl: 'bus.svg', category: 'transportation' },
	{ name: 'CABLE_CAR', svgUrl: 'cable-car.svg', category: 'transportation' },
	{ name: 'CAMPING_RV', svgUrl: 'camping-rv.svg', category: 'amusement' },
	{ name: 'CAMPING_SLEEPING_BAG', svgUrl: 'camping-sleeping-bag.svg', category: 'amusement' },
	{ name: 'CAMPING_TENT', svgUrl: 'camping-tent-2.svg', category: 'amusement' },
	{ name: 'CANOE_PADDLES', svgUrl: 'canoe-paddles.svg', category: 'sports' },
	{ name: 'CANOE', svgUrl: 'canoe.svg', category: 'sports' },
	{ name: 'CAR', svgUrl: 'car-1.svg', category: 'transportation' },
	{ name: 'CAR_GARAGE', svgUrl: 'car-garage.svg', category: 'transportation' },
	{ name: 'CAR_REPAIR_TOW_TRUCK', svgUrl: 'car-repair-tow-truck.svg', category: 'transportation' },
	{ name: 'CARD_GAME_CARD_CLUB', svgUrl: 'card-game-card-club.svg', category: 'amusement' },
	{ name: 'CASINO_CHIP', svgUrl: 'casino-chip-5.svg', category: 'amusement' },
	{ name: 'CASINO_LUCKY_HORSESHOE', svgUrl: 'casino-lucky-horseshoe.svg', category: 'amusement' },
	{ name: 'CAT_SITTING', svgUrl: 'cat-sitting.svg', category: 'animals' },
	{ name: 'CHAMPAGNE_BOTTLE_GLASS', svgUrl: 'champagne-bottle-glass.svg', category: 'food' },
	{ name: 'CHAMPAGNE_CHEERS', svgUrl: 'champagne-cheers.svg', category: 'food' },
	{ name: 'CHEF_GEAR_HAT', svgUrl: 'chef-gear-hat.svg', category: 'food' },
	{ name: 'CHESS_FIGURES', svgUrl: 'chess-figures.svg', category: 'amusement' },
	{ name: 'CIRCUS_TENT', svgUrl: 'circus-tent.svg', category: 'amusement' },
	{
		name: 'CLEAN_CAR_CHARGING_CABLE',
		svgUrl: 'clean-car-charging-cable.svg',
		category: 'transportation',
	},
	{ name: 'CLEAN_CAR_GAS', svgUrl: 'clean-car-gas.svg', category: 'transportation' },
	{ name: 'COCKTAIL_COCONUT', svgUrl: 'cocktail-coconut.svg', category: 'food' },
	{ name: 'COCKTAIL_MARTINI', svgUrl: 'cocktail-martini.svg', category: 'food' },
	{ name: 'COFFEE_COLD', svgUrl: 'coffee-cold.svg', category: 'food' },
	{ name: 'COFFEE_CUP_HOT', svgUrl: 'coffee-cup-hot.svg', category: 'food' },
	{ name: 'COFFEE_CUP', svgUrl: 'coffee-cup.svg', category: 'food' },
	{ name: 'COFFEE_STRAW', svgUrl: 'coffee-straw.svg', category: 'food' },
	{ name: 'CONCERT_ROCK', svgUrl: 'concert-rock-1.svg', category: 'amusement' },
	{ name: 'CONCERT_SING', svgUrl: 'concert-sing.svg', category: 'amusement' },
	{ name: 'DIVING_MASK', svgUrl: 'diving-mask.svg', category: 'sports' },
	{ name: 'DIVING_OXYGEN_TANK', svgUrl: 'diving-oxygen-tank.svg', category: 'sports' },
	{ name: 'DOG_BONE', svgUrl: 'dog-bone.svg', category: 'animals' },
	{ name: 'DOG_SIT', svgUrl: 'dog-sit.svg', category: 'animals' },
	{ name: 'EXOTIC_FOOD_KEBAB', svgUrl: 'exotic-food-kebab.svg', category: 'food' },
	{ name: 'EXOTIC_FOOD_OYSTER', svgUrl: 'exotic-food-oyster.svg', category: 'food' },
	{ name: 'FARMING_BARN_SILO', svgUrl: 'farming-barn-silo.svg', category: 'architecture' },
	{ name: 'FARMING_BARN', svgUrl: 'farming-barn.svg', category: 'architecture' },
	{ name: 'FARMING_FIELD_SUN', svgUrl: 'farming-field-sun.svg', category: 'nature' },
	{ name: 'FIREWORKS', svgUrl: 'fireworks.svg', category: 'amusement' },
	{ name: 'FISHING_FISH', svgUrl: 'fishing-fish.svg', category: 'sports' },
	{ name: 'FLOWER', svgUrl: 'flower.svg', category: 'nature' },
	{ name: 'GAS_LOAD', svgUrl: 'gas-load.svg', category: 'transportation' },
	{
		name: 'HISTORICAL_BUILDING_TOWER',
		svgUrl: 'historical-building-tower.svg',
		category: 'architecture',
	},
	{ name: 'HOTEL_DOUBLE_BED', svgUrl: 'hotel-double-bed-2.svg', category: 'accommodation' },
	{ name: 'HOTEL_SINGLE_BED', svgUrl: 'hotel-single-bed-2.svg', category: 'accommodation' },
	{ name: 'JELLYFISH', svgUrl: 'jellyfish-1.svg', category: 'animals' },
	{ name: 'LIGHTHOUSE', svgUrl: 'lighthouse.svg', category: 'architecture' },
	{ name: 'LIVESTOCK_COW', svgUrl: 'livestock-cow.svg', category: 'animals' },
	{ name: 'LIVESTOCK_RAM', svgUrl: 'livestock-ram-2.svg', category: 'animals' },
	{ name: 'MARINE_MAMMAL_TURTLE', svgUrl: 'marine-mammal-turtle-1.svg', category: 'animals' },
	{ name: 'MOOSE', svgUrl: 'moose.svg', category: 'animals' },
	{
		name: 'NAUTIC_SPORTS_SAILING_PERSON',
		svgUrl: 'nautic-sports-sailing-person.svg',
		category: 'sports',
	},
	{ name: 'NAUTIC_SPORTS_SCOOTER', svgUrl: 'nautic-sports-scooter.svg', category: 'sports' },
	{ name: 'NAUTIC_SPORTS_SURFING', svgUrl: 'nautic-sports-surfing.svg', category: 'sports' },
	{ name: 'OFFICIAL_BUILDING', svgUrl: 'official-building-3.svg', category: 'architecture' },
	{ name: 'OUTDOORS_BACKPACK', svgUrl: 'outdoors-backpack-1.svg', category: 'amusement' },
	{ name: 'OUTDOORS_BARBEQUE', svgUrl: 'outdoors-barbeque.svg', category: 'amusement' },
	{ name: 'OUTDOORS_CAMPFIRE', svgUrl: 'outdoors-camp-fire.svg', category: 'amusement' },
	{ name: 'OUTDOORS_HORSE', svgUrl: 'outdoors-horse.svg', category: 'sports' },
	{ name: 'OUTDOORS_KITE_FLYING', svgUrl: 'outdoors-kite-flying.svg', category: 'amusement' },
	{ name: 'OUTDOORS_TREE_VALLEY', svgUrl: 'outdoors-tree-valley.svg', category: 'nature' },
	{ name: 'PARK_BENCH', svgUrl: 'park-bench.svg', category: 'nature' },
	{ name: 'PARROT', svgUrl: 'parrot-1.svg', category: 'animals' },
	{ name: 'PARTY_CONFETTI', svgUrl: 'party-confetti.svg', category: 'amusement' },
	{ name: 'PARTY_MASK', svgUrl: 'party-mask.svg', category: 'amusement' },
	{ name: 'PASSPORT', svgUrl: 'passport.svg', category: 'transportation' },
	{ name: 'PETS_PAW', svgUrl: 'pets-paw.svg', category: 'animals' },
	{ name: 'PLANE', svgUrl: 'plane-1.svg', category: 'transportation' },
	{ name: 'PLANE_LAND', svgUrl: 'plane-land.svg', category: 'transportation' },
	{ name: 'PLANE_TAKE_OFF', svgUrl: 'plane-take-off.svg', category: 'transportation' },
	{
		name: 'PUBLIC_SERVICE_AMBULANCE',
		svgUrl: 'public-service-ambulance-1.svg',
		category: 'service',
	},
	{ name: 'PUBLIC_SERVICE_POLICE', svgUrl: 'public-service-police.svg', category: 'service' },
	{ name: 'RAILROAD_TRAIN', svgUrl: 'railroad-train-1.svg', category: 'transportation' },
	{ name: 'RICEBALL', svgUrl: 'Riceball--Streamline-Core.svg', category: 'food' },
	{ name: 'ROOSTER', svgUrl: 'rooster-2.svg', category: 'animals' },
	{ name: 'SAILING_BOAT', svgUrl: 'sailing-boat.svg', category: 'sports' },
	{ name: 'SAUNA_HEAT_STONE', svgUrl: 'sauna-heat-stone.svg', category: 'amusement' },
	{ name: 'SCOOTER', svgUrl: 'scooter-1.svg', category: 'transportation' },
	{ name: 'SHARK', svgUrl: 'shark-1.svg', category: 'animals' },
	{ name: 'SHOP', svgUrl: 'shop.svg', category: 'shopping' },
	{ name: 'SHOPPING_BASKET_HANDLE', svgUrl: 'shopping-basket-handle.svg', category: 'shopping' },
	{ name: 'SHOPPING_CART_EMPTY', svgUrl: 'shopping-cart-empty-1.svg', category: 'shopping' },
	{ name: 'SHOW_THEATER_MASKS', svgUrl: 'show-theater-masks.svg', category: 'amusement' },
	{ name: 'SOFT_DRINKS_GLASS', svgUrl: 'soft-drinks-glass.svg', category: 'food' },
	{ name: 'SPA_LOTUS', svgUrl: 'spa-lotus-1.svg', category: 'amusement' },
	{ name: 'SPA_STONE', svgUrl: 'spa-stone-1.svg', category: 'amusement' },
	{ name: 'SPIRITS_GLASS', svgUrl: 'spirits-glass.svg', category: 'food' },
	{ name: 'SPIRITS', svgUrl: 'spirits.svg', category: 'food' },
	{ name: 'SUSHI', svgUrl: 'Sushi--Streamline-Core.svg', category: 'food' },
	{ name: 'SWAN_COUPLE', svgUrl: 'swan-couple.svg', category: 'animals' },
	{ name: 'TAXI', svgUrl: 'taxi.svg', category: 'transportation' },
	{ name: 'TEA_KETTLE', svgUrl: 'tea-kettle.svg', category: 'food' },
	{ name: 'TRAILER', svgUrl: 'trailer.svg', category: 'amusement' },
	{ name: 'TREKKING_PERSON', svgUrl: 'trekking-person.svg', category: 'sports' },
	{ name: 'TRUCK', svgUrl: 'truck.svg', category: 'transportation' },
	{ name: 'TUK_TUK', svgUrl: 'tuk-tuk.svg', category: 'transportation' },
	{ name: 'WHALE_TAIL', svgUrl: 'whale-tail.svg', category: 'animals' },
	{ name: 'WILD_BIRD_FLY', svgUrl: 'wild-bird-fly-1.svg', category: 'animals' },
	{ name: 'WINE_GLASS_BOTTLE', svgUrl: 'wine-glass-bottle.svg', category: 'food' },
	{ name: 'WINE_GLASS', svgUrl: 'wine-glass.svg', category: 'food' },
]

export function getCustomIconSvgUrl(name: CustomIcon, blockType: BlockType): string | undefined {
	const icon = customIcons.find((icon) => icon.name === name)
	if (icon) return `/visuals/icons/custom/white/${icon.svgUrl}`

	return defaultUrls(blockType)
}

export function getCustomIconPinSvgUrl(name: CustomIcon, blockType: BlockType): string | undefined {
	const icon = customIcons.find((icon) => icon.name === name)
	if (icon) return `/visuals/icons/custom/pins/${icon.svgUrl}`

	return defaultUrls(blockType)
}

export function defaultUrls(blockType: BlockType): string {
	switch (blockType) {
		case BlockTypEnum.Destination:
			return '/visuals/icons/flag.svg'
		case BlockTypEnum.Place:
			return '/visuals/icons/location-pin.svg'
		case BlockTypEnum.Highlight:
			return '/visuals/icons/landscape-1.svg'
		case BlockTypEnum.CustomRegion:
			return '/visuals/icons/select-all.svg'
		case BlockTypEnum.CustomPin:
			return '/visuals/icons/pin-1.svg'
		case BlockTypEnum.Source:
			return '/visuals/icons/link-chain.svg'
		case BlockTypEnum.List:
			return '/visuals/icons/paginate-heart.svg'
		case BlockTypEnum.Day:
			return '/visuals/icons/calendar-add.svg'
		case BlockTypEnum.Requirements:
			return '/visuals/icons/check.svg'
		default:
			return '/visuals/icons/location-pin.svg'
	}
}
