import type { BlockType, CustomColor } from '$lib/graphql/types.d'
import { BlockType as BlockTypEnum } from '$lib/graphql/enums'

export const customColors: {
	name: CustomColor
	bgClassName: string
	textClassName: string
	hexCode: string
}[] = [
	{ name: 'RED', bgClassName: 'bg-red-600', textClassName: 'text-red-600', hexCode: '#ef4444' },
	{
		name: 'ORANGE',
		bgClassName: 'bg-orange-600',
		textClassName: 'text-orange-500',
		hexCode: '#f97316',
	},
	{
		name: 'AMBER',
		bgClassName: 'bg-amber-600',
		textClassName: 'text-amber-500',
		hexCode: '#f59e0b',
	},
	{
		name: 'YELLOW',
		bgClassName: 'bg-yellow-500',
		textClassName: 'text-yellow-500',
		hexCode: '#eab308',
	},
	{ name: 'LIME', bgClassName: 'bg-lime-600', textClassName: 'text-lime-600', hexCode: '#84cc16' },
	{
		name: 'GREEN',
		bgClassName: 'bg-green-600',
		textClassName: 'text-green-600',
		hexCode: '#22c55e',
	},
	{ name: 'TEAL', bgClassName: 'bg-teal-600', textClassName: 'text-teal-600', hexCode: '#14b8a6' },
	{ name: 'SKY', bgClassName: 'bg-sky-600', textClassName: 'text-sky-600', hexCode: '#0ea5e9' },
	{ name: 'BLUE', bgClassName: 'bg-blue-600', textClassName: 'text-blue-600', hexCode: '#3b82f6' },
	{
		name: 'INDIGO',
		bgClassName: 'bg-indigo-600',
		textClassName: 'text-indigo-600',
		hexCode: '#6366f1',
	},
	{
		name: 'PURPLE',
		bgClassName: 'bg-purple-600',
		textClassName: 'text-purple-600',
		hexCode: '#a855f7',
	},
	{
		name: 'FUCHSIA',
		bgClassName: 'bg-fuchsia-600',
		textClassName: 'text-fuchsia-600',
		hexCode: '#d946ef',
	},
	{ name: 'PINK', bgClassName: 'bg-pink-800', textClassName: 'text-pink-600', hexCode: '#ec4899' },
	{ name: 'ROSE', bgClassName: 'bg-rose-800', textClassName: 'text-rose-600', hexCode: '#fb7185' },
]

export function getCustomColorHexCode(name: CustomColor, blockType: BlockType): string | undefined {
	const color = customColors.find((color) => color.name === name)

	if (color) return color.hexCode

	return defaultHexCodes(blockType)
}

export function getCustomColorBgClassName(
	name: CustomColor,
	blockType: BlockType,
): string | undefined {
	const color = customColors.find((color) => color.name === name)

	if (color) return color.bgClassName

	return defaultBgColors(blockType)
}

export function getCustomColorTextClassName(
	name: CustomColor,
	blockType: BlockType,
): string | undefined {
	const color = customColors.find((color) => color.name === name)

	if (color) return color.textClassName

	return defaultTextColors(blockType)
}

export function defaultTextColors(blockType: BlockType): string {
	switch (blockType) {
		case BlockTypEnum.Destination:
			return 'text-pink-600'
		case BlockTypEnum.Place:
			return 'text-red-600'
		case BlockTypEnum.Highlight:
			return 'text-yellow-500'
		case BlockTypEnum.CustomRegion:
			return 'text-orange-500'
		case BlockTypEnum.CustomPin:
			return 'text-purple-600'
		case BlockTypEnum.Source:
			return 'text-blue-600'
		case BlockTypEnum.List:
			return 'text-green-600'
		case BlockTypEnum.Day:
			return 'text-teal-600'
		case BlockTypEnum.Requirements:
			return 'text-sky-600'
		default:
			return 'text-purple-600'
	}
}

export function defaultBgColors(blockType: BlockType): string {
	switch (blockType) {
		case BlockTypEnum.Destination:
			return 'bg-pink-700'
		case BlockTypEnum.Place:
			return 'bg-red-600'
		case BlockTypEnum.Highlight:
			return 'bg-yellow-500'
		case BlockTypEnum.CustomRegion:
			return 'bg-orange-500'
		case BlockTypEnum.CustomPin:
			return 'bg-purple-600'
		case BlockTypEnum.Source:
			return 'bg-blue-600'
		case BlockTypEnum.List:
			return 'bg-green-600'
		case BlockTypEnum.Day:
			return 'bg-teal-600'
		case BlockTypEnum.Requirements:
			return 'bg-green-600'
		default:
			return 'bg-purple-600'
	}
}

export function defaultHexCodes(blockType: BlockType): string {
	switch (blockType) {
		case BlockTypEnum.Destination:
			return '#ec4899'
		case BlockTypEnum.Place:
			return '#ef4444'
		case BlockTypEnum.Highlight:
			return '#eab308'
		case BlockTypEnum.CustomRegion:
			return '#f97316'
		case BlockTypEnum.CustomPin:
			return '#a855f7'
		case BlockTypEnum.Source:
			return '#3b82f6'
		case BlockTypEnum.List:
			return '#22c55e'
		case BlockTypEnum.Day:
			return '#14b8a6'
		default:
			return '#a855f7'
	}
}
